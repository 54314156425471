import { Link } from "react-router-dom";

function Hero() {
    return (
        <div className="hero" id="hero">
            <div className="hero-bg">
                <img src={process.env.PUBLIC_URL + "/images/hero/HeroPageBacground.png"} alt="" /></div>
            <div className="hero-container">
                <div className="hero-left">
                    <div className="green supersize">ALWAYS</div>
                    <div className="supersize">TALK</div> 
                    <div className="supersize">ABOUT</div>
                    <div className="supersize">PROJECT</div>
                    <div className="supersize">DEFI</div> 
                    <div className="hero-text">Project defi is a utility focused DAO NFT project. Giving you not only access but partial ownership of the platform for all your creative needs, it also presents a great opportunity to make passive income. Educating and inspiring people to realize the true potential of Web3.
                        <br></br><br></br>Please join our discord and all other accounts.</div>
                        <div style={{ display: 'flex' }}>
                        <button style={{marginRight: '15px'}}type="button" className="button-pink" display="inline-block"><a href="https://discord.gg/SxPAbhmqjE" target="_blank" rel="noreferrer" display="inline-block"><div className="button-text">JOIN&nbsp;DISCORD</div><img className="discord-button" src={process.env.PUBLIC_URL + "/images/hero/DiscordLogoHeroPageButton.png"} alt="" /></a></button>
                        {/*Change back to <Link to="/app">*/}
                        <button type="button" className="button-pink" display="inline-block"><Link to="/auth">
                            <div className="button-text">Access Profile (BETA)</div></Link>
                        </button>
                    </div>
                    </div>
                    <div className="hero-right">
                        <img className="hero-smiley" src={process.env.PUBLIC_URL + "/images/hero/HeroPageSmileyLogoBlack.png"} alt="" />
                    </div>
            </div>
            
            
            
            
            
        </div>
    )
}    

export default Hero;