import Main from "./main";
// import CreateProfile from "./CreateProfile";
// import EditProfile from "./EditProfile";
// import Profile from "./ProfilePage";
import Auth from "./components/Auth";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tos from "./components/Tos";
import WebPlatform from "./components/WebPlatform";
import AccountDetails from "./components/AccountDetails";


function App() {

  return (
    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/auth" element={<Auth/>} />
        <Route path="/tos" element={<Tos/>} />
        <Route path="/app" element={<WebPlatform/>} />
        <Route path="/account-details" element={<AccountDetails/>}/>
        {/* <Route path="/ProfilePage" element={<Profile/>} /> 
        <Route path="/CreateProfile" element={<CreateProfile/>} />
        <Route path="/EditProfile" element={<EditProfile/>} /> */}
      </Routes>
      <ToastContainer autoClose={4000} hideProgressBar theme="colored" />
      </BrowserRouter>
    
  );
}

export default App;
