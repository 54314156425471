import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import Web3 from 'web3';
import ProgressLog from './ProgressLog';
import SignupForm from './FormSignup';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FormLogin from './FormLogin.js'

const Auth = () => {
    const [walletAddress, setWalletAddress] = useState('');
    const [web3, setWeb3] = useState(null);
    const [userHasNft, setUserHasNft] = useState(false); // Initialize as false
    const [progressLog, setProgressLog] = useState(['Progress Log:']);
    const [showSignButton, setShowSignButton] = useState(false); // Show/Hide the sign button
    const [showCreateButton, setShowCreateButton] = useState(false); // Show/Hide the create button
    const [showSignupForm, setShowSignupForm] = useState(false); // Show/Hide the signup form
    const [userHasAccessToken, setUserHasAccessToken] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
    const checkAccessToken = async () => {
        try {
            // Retrieve the accessTokenA from the cookie
            const accessTokenA = Cookies.get('accessTokenA');

            if (accessTokenA) {
                // Send the accessTokenA to the backend for validation
                const response = await axios.post('/api/verifyAccessTokenA', { accessTokenA });

                if (response.data.valid) {
                // Token is valid, navigate to the content page
                navigate('/app');
                }
            } 
        } catch (error) {
            console.log('Token validation failed:', error);
        }
    };
        // Call the function to check the accessTokenA on component mount
        checkAccessToken();
    }, [navigate]);

    const connectWallet = async () => {
        if (window.ethereum) {
        try {
            await window.ethereum.send('eth_requestAccounts');
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);

            const accounts = await web3Instance.eth.getAccounts();
            setWalletAddress(accounts[0]);
            setProgressLog(prevLog => [...prevLog, `Your connected wallet is: ${accounts[0]}`]);

            // Show the sign button
            setShowSignButton(true);
        } catch (error) {
            setProgressLog(prevLog => [...prevLog, '.', 'Error: ' + error.message]);
        }
        }
    };

    const signMessage = async () => {
        if (web3) {
        try {
            const signature = await web3.eth.personal.sign('Message to sign', walletAddress, '');
            const res = await fetch('/api/signNft?signature=' + signature);
            if (!res.ok) {throw new Error('Failed to fetch data');}
            const data = await res.json();
            if (data.error) {throw new Error(data.error);}
            setUserHasNft(data.hasNft);
            if (data.hasNft){
                getAccessTokenC();
            }
            setProgressLog(prevLog => [
            ...prevLog,
            '.',
            `We confirm you own ${data.balance} of Project-Defi NFT(s)!`,
            ]);

            // Show the create button
            setShowCreateButton(true);
        } catch (error) {
            setProgressLog(prevLog => [...prevLog, '.', 'Error: ' + error.message]);
        }
        } else {
        setProgressLog(prevLog => [
            ...prevLog,
            '.',
            "You don't have a Project-Defi NFT in this wallet.",
        ]);
        }
    };

    const getAccessTokenC = async () => {
    try {
        const response = await axios.post('/api/getAccessTokenC', { walletAddress: walletAddress });
        const { accessToken } = response.data;
        Cookies.set('accessTokenC', accessToken);
        setUserHasAccessToken(true);
    } catch (error) {
        console.error(error);
    }
    };

    const showSignup = () => {
        setShowSignupForm(true);
    };

    return (
        <Container>
        <Background/>
        <ButtonColumn>
            <Section>
            <button
                style={{ width: '260px', justifyContent: 'center', marginTop: '7vh' }}
                onClick={() => connectWallet()}
                className="button-pink"
            >
                Connect your wallet
            </button>
            </Section>
            {showSignButton && (
            <>
                <Section>
                <Line
                    src={process.env.PUBLIC_URL + '/images/auth/TopToBottomLine.png'}
                    alt=""
                />
                <button
                    style={{ width: '260px', justifyContent: 'center' }}
                    onClick={() => signMessage()}
                    className="button-pink"
                >
                    Sign your NFT
                </button>
                </Section>
                {showCreateButton && (
                <Section>
                    <Line
                    src={process.env.PUBLIC_URL + '/images/auth/TopToBottomLine.png'}
                    alt=""
                    />
                    <button
                    style={{ width: '260px', justifyContent: 'center' }}
                    onClick={() => showSignup()}
                    className="button-pink"
                    disabled={!userHasNft && !userHasAccessToken} // Disable the button when userHasNft is false
                    >
                    Get Access
                    </button>
                </Section>
                )}
            </>
            )}
        </ButtonColumn>
        {!showSignupForm ? (
            <ProgressColumn>
            <ProgressLog log={progressLog} />
            </ProgressColumn>
        ) : (
            <div style={{display:'flex', flexDirection:'column'}}>
            <SignupForm/>
            <FormLogin/>
            </div>
        )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
`;

const ButtonColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ProgressColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Section = styled.div`
    margin: 10px;
`;

const Line = styled.img`
    margin-left: 120px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind other elements */
  background-image: url("/images/hero/HeroPageBacground.png");
  background-size: cover; /* Ensures the image covers the entire screen */
  background-repeat: no-repeat; /* Prevents repeating the image if it's smaller than the screen */
  `

export default Auth;
