import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


const AccountDetails = () => {
  const [profileName, setProfileName] = useState('');
  const [profileGender, setProfileGender] = useState('Male');
  const [profileAge, setProfileAge] = useState('')
  const [profilePhone, setProfilePhone] = useState('');
  const [profileInterest, setProfileInterest] = useState('');
  const [showCommonInterests, setShowCommonInterests] = useState(false);
  const [textAreaClicked, setTextAreaClicked] = useState(false);
  const [accountAvailableAlert, setAccountAvailableAlert] = useState('');
  const [accountNameTaken, setAccountNameTaken] = useState(false);
  const [phoneEntered, setPhoneEntered] = useState(false);
  const [ageEntered, setAgeEntered] = useState(false);
  const [accountAgeAlert, setAccountAgeAlert] = useState('');
  const [accountPhoneAlert, setAccountPhoneAlert] = useState('');
  const [numInterestsSelected, setNumInterestsSelected] = useState(0);
  const navigate = useNavigate();

  const commonInterests = [
  "Music",
  "Entertainment",
  "Gaming",
  "DIY and Crafts",
  "Fashion and Beauty",
  "Food and Cooking",
  "Travel",
  "Technology",
  "Health and Fitness",
  "Education",
  "Sports",
  "Comedy",
  "Animation",
  "Vlogging",
  "Science and Technology",
  "Art and Design",
  "News and Politics",
  "Book Reviews",
  "Motivation and Inspiration",
  "True Crime",
  "Documentary",
  "Parenting",
  "History",
  "Pets and Animals",
  "Gardening",
  "Dance",
  "Photography",
  "Finance and Money",
  "Cars and Automotive",
  "Yoga and Meditation",
  "Nature and Wildlife",
  "Fashion",
  "Wierd Shit",
  "Challenges",
  "Reaction Videos",
  "Celebrity News",
  "Paranormal and Supernatural",
  "Home Décor",
  "Movie Reviews",
  "Language Learning",
  "Fitness Challenges",
  "Personal Development",
  "Nature Travel",
  "Hair Care",
  "Career Advice",
  "Movie Trailers",
  "Morning Routines",
  "Sports Highlights",
  "Relationship Advice",
  "Health and Nutrition",
  "Travel Guides",
  "Life Hacks",
  "Adventure Travel",
  "Food Reviews",
  "Book Recommendations",
  "Technology Reviews",
  "Car Reviews",
  "Yoga Tutorials",
  "Food Challenges",
  "Tech Unboxings"
];

useEffect(() => {
    const checkAccessToken = async () => {
        try {
            // Retrieve the accessTokenA from the cookie
            const accessTokenA = Cookies.get('accessTokenA');
            if (!accessTokenA){
              navigate('/auth');
            }
            else if (accessTokenA) {
                // Send the accessTokenA to the backend for validation
                const response = await axios.post('/api/verifyAccessTokenA', { accessTokenA });

                if (response.data.valid) {
                // Token is valid, check status of the account
                try {
                const accessTokenA = Cookies.get('accessTokenA');
                // Make a request to the server to check the user's status
                const response = await fetch('/api/getAccountDetails', {
                    method: 'POST',
                    // Include any necessary request data (e.g., userId) in the request body
                    body: JSON.stringify({ accessTokenA }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    // User has access to the normal content, continue rendering the page
                    navigate('/app');
                } else if (response.status === 403) {
                    // Handle different 403 error scenarios
                    const errorData = await response.json();

                    if (errorData.reason === 'terms-not-signed') {
                        // User has not signed the terms of services, redirect to the terms page
                        navigate('/tos');
                    } else if (errorData.reason === 'account-details-incomplete') {
                        // User's account details are incomplete, redirect to the account details page
                        navigate('/account-details');
                    } else {
                        // Unknown 403 error scenario, redirect to the error page
                        navigate('/auth');
                    }
                } else {
                    // Handle other error cases (e.g., server error)
                    // Redirect to an error page or display an error message
                    navigate('/auth');
                }
            } catch (error) {
                console.error('Error checking user status:', error);
                // Handle error cases (e.g., network error)
                // Redirect to an error page or display an error message
                navigate('/auth');
            }
                }
            } 
        } catch (error) {
            console.log('Token validation failed:', error);
        }
    };
        // Call the function to check the accessTokenA on component mount
        checkAccessToken();
    }, [navigate]);

    useEffect(() => {
        const getAccountNameAvalibility = async () => {
            try {
            // Get the JWT token from where you store it (e.g., localStorage, cookies, etc.)
            const accessTokenA = Cookies.get('accessTokenA');

            // Set the JWT token in the request headers
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessTokenA}`;

            const response = await axios.get(`/api/getAccountNameAvalibility`, {
                params: {
                profileName: profileName,
                },
            });

                if (response.data.taken) {

                    setAccountAvailableAlert(response.data.message);
                    setAccountNameTaken(true);
                }
                else {
                    setAccountAvailableAlert(response.data.message);
                    setAccountNameTaken(false);
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        if (profileName) {
            getAccountNameAvalibility();
        }
    }, [profileName]);

    useEffect(() => {
  const numCommas = (profileInterest.match(/,/g) || []).length;
  setNumInterestsSelected(numCommas + 1);
}, [profileInterest]);

const handlePhoneChange = (e) => {
  const value = e.target.value;
  // Check if the input is either empty or contains only digits
  if (/^$|^\d+$/.test(value)) {
    setProfilePhone(value);
    setPhoneEntered(!!value); // Update phoneEntered based on whether the value is truthy
  }
};

const handleAgeChange = (e) => {
  const value = e.target.value;
  // Check if the input is either empty or contains only digits
  if (/^$|^\d+$/.test(value)) {
    setProfileAge(value);
    setAgeEntered(!!value); // Update ageEntered based on whether the value is truthy
  }
};

  const handleInterestInputClick = () => {
    setShowCommonInterests(true);
    if (!textAreaClicked) {
      setTextAreaClicked(true);
    }
  };

const handleInterestChoiceClick = (interest) => {
  setProfileInterest((prevInterest) => {
    if (!prevInterest.includes(interest)) {
      return prevInterest ? prevInterest + ', ' + interest : interest;
    }
    return prevInterest;
  });
  setNumInterestsSelected((prevNum) => prevNum + 1);
};

const handleSubmit = async () => {
  // Prepare the data to be sent to the backend
  const userData = {
    profileName,
    profileGender,
    profileAge,
    profilePhone,
    profileInterest,
  };

  try {
      const accessTokenA = Cookies.get('accessTokenA');
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessTokenA}`;

      const response = await axios.put('/api/updateProfileData', userData);

      console.log(response.data);

      if (response.data.success) {
        // Successful response from the server, navigate to /app
        navigate('/app');
      } else {
        // Handle other responses or conditions if needed
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
};

  return (
    <CenteredContainer>
      <Background/>
      <FormContainer>
        <FormRow>
          <InputLabel>Profile name:</InputLabel>
          <div style={{display:'flex', flexDirection:'column', flex: '1'}}>
            <InputField
              type="text"
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
            />
            <Message error={accountNameTaken.toString()}>
              {accountAvailableAlert}
            </Message>
          </div>
        </FormRow>
        <FormRow>
          <InputLabel>Gender:</InputLabel>
          <div style={{display:'flex', flexDirection:'column', flex: '1'}}>
            <SelectField value={profileGender} onChange={(e) => setProfileGender(e.target.value)}>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Delusional</option>
            </SelectField>
          </div>
        </FormRow>
        <FormRow>
          <InputLabel>Age:</InputLabel>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <InputField
              type="age"
              value={profileAge}
              onChange={handleAgeChange}
              onFocus={() => setAccountAgeAlert(ageEntered ? '' : 'Please enter your age')}
            />
            <div>{!ageEntered && accountAgeAlert}</div>
          </div>
        </FormRow>
        <FormRow>
          <InputLabel>Phone number:</InputLabel>
            <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
              <InputField
                type="tel"
                value={profilePhone}
                onChange={handlePhoneChange}
                onFocus={() => setAccountPhoneAlert(phoneEntered ? '' : 'Please enter your phone number')}
              />
              <div>{!phoneEntered && accountPhoneAlert}</div>
            </div>
        </FormRow>
        <FormRow>
          <InputLabel>Your interests:</InputLabel>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <TextAreaField
              rows={textAreaClicked ? 5 : 1}
              value={profileInterest}
              onChange={(e) => setProfileInterest(e.target.value)}
              onClick={handleInterestInputClick}
              onFocus={handleInterestInputClick}
            />
            {textAreaClicked && numInterestsSelected < 5 && (
              <Message>
              Please select at least 5 interests with commas in between them.
            </Message>
            )}
          </div>
        </FormRow>
        {showCommonInterests && (
        <CommonInterestsContainer>
          {commonInterests.map((interest, index) => (
            <InterestChoice key={index} onClick={() => handleInterestChoiceClick(interest)}>
              {interest}
            </InterestChoice>
          ))}
        </CommonInterestsContainer>
      )}
        <SaveButton 
        disabled={!phoneEntered || !ageEntered || accountNameTaken || numInterestsSelected < 5}
        onClick={handleSubmit}>
          Save Profile Details
        </SaveButton>
      </FormContainer>
    </CenteredContainer>
  );
};

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: green;
`;

const FormContainer = styled.div`
  border: 1px solid green;
  padding: 10px;
  width: 600px;
`;

const CommonInterestsContainer = styled.div`
  max-height: 200px;
  overflow-y: auto; 
  display: flex;
  flex-wrap: wrap;
  border: 1px solid green;
  padding: 10px;
  margin-top: 10px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const InputLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  font-size: 18px;
`;

const InputField = styled.input`
  flex: 1;
  padding: 5px;
  border: 1px solid green;
  width: 100%;
  background-color: black;
  color: green;
`;

const SelectField = styled.select`
  flex: 1;
  padding: 5px;
  border: 1px solid green;
  width: 100%;
  background-color: black;
  color: green;
`;

const InterestChoice = styled.div`
  padding: 5px;
  cursor: pointer;
  border: 1px solid green;
  margin: 5px;
  border-radius: 5px;

  &:hover {
    background-color: rgb(241, 4, 176);
    color: white;
    border: none;
  }
`;

const TextAreaField = styled.textarea`
  flex: 1;
  padding: 5px;
  border: 1px solid green;
  width: 100%;
  resize: vertical; /* Allow vertical resizing */
  background-color: black;
  color: green;
`;

const SaveButton = styled.button`
  background-color: rgb(241, 4, 176);
  display: inline-block;
  color: white;
  padding: 6px 12px;
  font-family: "Courier";
  font-weight: bold;
  border: none;
  margin-top: 10px;
`;

const Message = styled.div`
  color: ${(props) => (props.error === 'true' ? 'red' : 'green')};
  margin-top: 5px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind other elements */
  background-image: url("/images/hero/HeroPageBacground.png");
  background-size: cover; /* Ensures the image covers the entire screen */
  background-repeat: no-repeat; /* Prevents repeating the image if it's smaller than the screen */
`;


export default AccountDetails;
